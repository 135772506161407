import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { environment } from '@environments/environment';
import { Group, GroupMembership } from '@models/ncx/groups-discussions';
import { PERMISSIONS } from '@models/users';
import { PermissionsService } from '@services/profile/permissions.service';
import { CommonService } from 'src/app/services/common-service';
import { ToastService } from 'src/app/services/toastService/toastMessage.service';
import { UtilityService } from 'src/app/services/utility.service';

@Component({
  selector: 'app-create-group',
  templateUrl: './create-group.component.html',
  styleUrls: ['./create-group.component.scss']
})
export class CreateGroupComponent implements OnInit {

  groupText = null;

  slackName = '';

  slackChannelNames = [];

  groupDetails: Group;

  isLoaded: boolean;

  groupApiURL: any;

  groupId: any;

  isNewGroup: boolean;

  buttonLabel: string;

  groupAdminListToSave: any;

  groupUrl;

  slackIntegration = true;

  groupMembership: Array<GroupMembership> = [];

  isAdminOrOwner: any;

  isPublishDisable = false;

  @ViewChild('searchGroupTitleText') searchGroupTitleText: ElementRef;

  constructor(
    private reRoute: Router,
    private router: ActivatedRoute,
    private cService: CommonService,
    private toastService: ToastService,
    private uS: UtilityService,
    private permissions: PermissionsService
  ) { }

  ngOnInit() {

    this.groupApiURL = environment.getGroupsURL;
    this.groupDetails = {} as Group;
    this.isNewGroup = true;
    this.buttonLabel = 'Create';
    this.router.params.subscribe(
      (params: Params) => {

        this.setGroupId(params);

      });

  }

  setGroupId(params) {

    if (params && params.groupId) {

      this.isLoaded = false;
      this.isNewGroup = false;
      const id = params.groupId;

      this.groupId = id.substr(1);
      this.getGroupDetails(this.groupId);
      this.getGroupMembers(this.groupId, 'MEMBER');
      this.buttonLabel = 'Update';

    } else {

      this.isLoaded = true;
      this.createGroupObject(null);
      this.buttonLabel = 'Create';

    }

  }

  redirectTo() {

    if (this.groupId) {

      this.reRoute.navigate(['/group-details/:' + this.groupId]);

    } else {

      this.reRoute.navigate(['ncx/group-landing']);

    }

  }

  groupNameChange(value) {

    value = value.toLowerCase().replace(/[^a-zA-Z0-9]/g, '_');
    this.groupUrl = window.location.origin + '/groups/' + value;

  }

  createGroupObject(goupObj) {

    this.groupDetails.groupId = (goupObj && goupObj.groupId) ? goupObj.groupId : null;
    this.groupId = (goupObj && goupObj.groupId) ? goupObj.groupId : null;
    this.groupDetails.groupName = (goupObj && goupObj.groupName) ? goupObj.groupName : null;
    this.groupText = (goupObj && goupObj.groupName) ? goupObj.groupName : null;
    this.groupDetails.isGroupPublished = (goupObj && goupObj.isGroupPublished) ? goupObj.isGroupPublished : false;
    this.groupDetails.groupAccess = (goupObj && goupObj.groupAccess) ? goupObj.groupAccess : 'PUBLIC';
    this.groupDetails.groupUrl = (goupObj && goupObj.groupUrl) ? goupObj.groupUrl : null;

    // tslint:disable-next-line:max-line-length
    this.groupDetails.isSlackIntegration = (goupObj && goupObj.isSlackIntegration && goupObj.isSlackIntegration === true) ? 'enable' : 'disable';
    this.buttonLabel = (goupObj && goupObj.groupId) ? 'Update' : 'Create';
    if (goupObj && goupObj.groupMembership) {

      // this.getGroupAdmin(goupObj.groupMembership);
    }

  }

  getGroupPrivacy(privacyValue) {

    this.groupDetails.groupAccess = privacyValue;

  }

  getGroupAdmin(groupAdminList) {

    this.groupAdminListToSave = groupAdminList.map((user) => {

      return {
        memberUserId: (user.memberUserId && user.memberUserId.userId) ? user.memberUserId.userId : user.memberUserId,
        memberType: user.memberType
      };

    });

    /*Code refractor based upon backend changes Start*/
    const groupMembers = [];

    groupAdminList.forEach((user) => {

      if (user.membershipCategory === 'MEMBER') {

        groupMembers.push({
          groupId: (this.groupId) ? this.groupId : 0,
          userId: parseFloat((user.memberUserId && user.memberUserId.userId) ? user.memberUserId.userId : user.memberUserId),
          membershipCategory: 'MEMBER',
          membershipType: user.membershipType,
          membershiptInvitationToken: null,
          createDateTime: null,
          updateDateTime: null,
          createUserId: null,
          updateUserId: null,
          deleted: (user && user.deleted) ? user.deleted : false
        });

      }

    });
    this.groupMembership = groupMembers;

    /*Code refractor based upon backend changes End*/
    this.groupDetails.groupMembership = groupAdminList;

  }

  haveEditAccess(group) {

    console.log('haveEditAccess : ', group);
    const userId = localStorage.getItem('userId');

    if (group && group.groupMembership) {


      // tslint:disable-next-line:max-line-length
      const isAdmin = group.groupMembership.filter(
        member => member.userId.userId.toString() === userId.toString() && (member.membershipType === 'OWNER' || member.membershipType === 'ADMIN'));

      if (isAdmin && isAdmin.length) {

        return true;

      } else {

        return false;

      }

    }
    return false;

  }

  checkValidation(groupDetailsObj) {

    const groupOwner = groupDetailsObj.groupMembership.filter((member) => member.membershipType === 'OWNER')[0];

    if (groupOwner === null || groupOwner === undefined) {

      this.toastService.createMessage('warning', 'Please select Group Owner.');
      return false;

    }
    return true;

  }

  /*API for CRUD operation on Group Start */
  getGroupDetails(groupId) {

    const queryStr = `/${groupId}`;

    this.cService.serviceRequestCommon('get', this.groupApiURL, queryStr).subscribe((res: any) => {

      this.slackChannelNames = res.slackChannelName;
      this.isLoaded = true;
      this.groupNameChange(res.groupName);
      if (res && res.groupMembership) {

        this.isAdminOrOwner = this.uS.hasAdminAccess(res.groupMembership) || this.permissions.hasPermissionTo(PERMISSIONS.EDIT_GROUP, { createUser: this.groupDetails?.createUser });

      }

      // this.groupUrl =  'dev.ncx.nbcuni.com/groups/' + res.groupName;
      if (res.isDeleted === true) {

        this.toastService.createMessage('warning', 'Group doesnot Exist.');
        this.reRoute.navigate(['/create-group']); // need to change redirection link upon confirmation

      } else if (res.groupAccess === 'PRIVATE' && !this.isAdminOrOwner) {

        this.toastService.createMessage('warning', 'You do not have Permission to access this group');
        setTimeout(() => {

          this.reRoute.navigate((['/ncx/group-landing']));

        }, 1000);
        this.isLoaded = true;
        return;

      } else {

        this.isLoaded = true;
        this.createGroupObject(res);

      }
      this.slackNameLength();

    },
    (err) => {

      this.isLoaded = true;
      if (err === 'GROUP-003') {

        this.toastService.createMessage('error', 'You Do Not Have Access to the Group');
        setTimeout(() => {

          this.reRoute.navigate(['ncx/group-landing']);

        }, 500);

      } else {

        this.toastService.createMessage('error', err);

      }

    }
    );

  }

  getGroupMembers(groupId, tab) {

    this.isLoaded = false;
    const queryStr = `/${groupId}/memberDetails?category=${tab}`; // members category='MEMBER'

    this.cService.serviceRequestCommon('get', this.groupApiURL, queryStr).subscribe((res: any) => {

      console.log('getGroupDetailsByCategory : ', res);
      if (res && res.groupMembership) {

        this.getGroupAdmin(res.groupMembership);

      }
      this.isLoaded = true;

    }, () => {

      this.isLoaded = true;

    });

  }

  slackNameLength() {

    if (this.slackChannelNames && this.slackChannelNames.length <= 0) {

      this.slackIntegration = true;

    } else {

      this.slackIntegration = false;

    }

  }

  saveGroupDetails() {

    if (this.groupText === '' || this.groupText === null
      || this.groupText === undefined) {

      this.toastService.createMessage('warning', 'Please Provide the Group Name');
      return false;

    }
    this.isLoaded = false;
    const groupDetailsObj = JSON.parse(JSON.stringify(this.groupDetails));

    groupDetailsObj.groupName = this.groupText;
    groupDetailsObj.slackChannelName = this.slackChannelNames;
    groupDetailsObj.isGroupPublished = true;
    if (groupDetailsObj.isSlackIntegration === 'enable') {

      groupDetailsObj.isSlackIntegration = true;

    } else {

      groupDetailsObj.isSlackIntegration = false;

    }

    // groupDetailsObj.groupMembers = this.groupAdminListToSave;
    groupDetailsObj.groupMembers = null;
    groupDetailsObj.groupMembership = this.groupMembership;
    console.log('TEST GROUP NEW MEMBERSHIP ', this.groupMembership);
    const type = (this.groupId) ? 'put' : 'post';

    const queryStr = (this.groupId) ? `/${this.groupId}` : '';


    // OWNER validation
    const ifGroupOwnerExist = this.checkValidation(groupDetailsObj);

    if (!ifGroupOwnerExist) {

      this.isLoaded = true;
      return;

    }
    this.isPublishDisable = true;
    this.cService.serviceRequestCommon(type, this.groupApiURL, queryStr, groupDetailsObj).subscribe((res: any) => {

      this.isLoaded = true;
      if (type === 'post') {

        this.toastService.createMessage('success', 'The Group has Been Successfully created');

      } else {

        this.toastService.createMessage('success', 'The Group has Been Successfully Updated');

      }
      this.isLoaded = true;
      this.reRoute.navigate(['/group-details/:' + res.groupId]);
      this.createGroupObject(res);

    },
    (err) => {

      this.isLoaded = true;
      this.isPublishDisable = false;
      this.toastService.createMessage('error', err);

    }
    );

  }

  deleteGroupById(groupId) {

    this.isLoaded = false;
    const queryStr = `/${groupId}`;

    this.cService.serviceRequestCommon('delete', this.groupApiURL, queryStr).subscribe(() => {

      this.isLoaded = true;
      this.toastService.createMessage('success', 'The Group has Been Successfully deleted');
      this.isLoaded = true;
      this.reRoute.navigate(['/group-landing']); // need to change redirection link upon confirmation

    },
    (err) => {

      this.isLoaded = true;
      this.toastService.createMessage('error', err);

    }
    );

  }

  /*API for CRUD operation on Group End */
  AddSlackChannel(event) {


    // console.log(event);
    if (!this.slackChannelNames) {

      this.slackChannelNames = [];

    }

    if (event.charCode === 13 && this.slackName.trim().length > 0) {

      if (!this.slackChannelNames.includes(this.slackName.trim())) {


        //  this.slackName = this.slackName.toUpperCase();
        this.slackChannelNames.push(this.slackName.trim());
        this.slackName = '';
        this.slackNameLength();

      } else {

        this.toastService.createMessage('warning', 'Slack channel name already added to group.');

      }

    } else {

      if (this.slackName.length > 0 && this.slackName.trim().length === 0 && event.charCode === 13) {

        this.slackName = '';
        this.toastService.createMessage('warning', 'Enter Slack channel name.');

      }

    }

  }

  AddSlackChannelonClick(_event) {


    // console.log(event);
    if (!this.slackChannelNames) {

      this.slackChannelNames = [];

    }
    if (this.slackName.trim().length > 0) {

      if (!this.slackChannelNames.includes(this.slackName.trim())) {


        //  this.slackName = this.slackName.toUpperCase();
        this.slackChannelNames.push(this.slackName.trim());
        this.slackName = '';
        this.slackNameLength();

      } else {

        this.toastService.createMessage('warning', 'Slack channel name already added to group.');

      }

    } else {

      if (this.slackName.length > 0 && this.slackName.trim().length === 0) {

        this.slackName = '';
        this.toastService.createMessage('warning', 'Enter Slack channel name.');

      }

    }

  }

  removeTag(tag) {

    const index = this.slackChannelNames.indexOf(tag);

    if (index > -1) {

      this.slackChannelNames.splice(index, 1);
      this.slackNameLength();

    }

  }

}
