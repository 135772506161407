import { Injectable } from '@angular/core';
import { AppConstants } from '@constants/app.constants';
import { environment } from '@environments/environment';
import { UrlRedirectService } from '@services/url-redirect.service';
import { Common } from '@utilities/common';
import * as jwt_decode from 'jwt-decode';
import { BehaviorSubject } from 'rxjs';
import { CommonFunctionsHelper } from './comon.functions.helper';
import { UtilityService } from '@services/utility.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private codeVerifier!: string;
  checkSession = new BehaviorSubject(true);

  constructor(
    private commonFunctionsHelper: CommonFunctionsHelper,
    private urlRedirectService: UrlRedirectService
  ) {
  }

  public getDecodedAccessToken(token: string): any {

    let tokenDecode: any;

    try {

      tokenDecode = jwt_decode(token);

    } catch (Error) {

      tokenDecode = null;

    }
    return tokenDecode;

  }

  public encodeKey(key: string): string {

    return encodeURIComponent(key);

  }

  public async getOpenIdToken() {

    const hostName = window.location.origin;

    let domainName = window.location.href;

    // For running application in Local to remove deep links
    if (domainName.startsWith('http://localhost:4200')) {

      domainName = 'http://localhost:4200';

    }

    // For error screens point back to home page
    if (domainName.includes('/error')) {

      domainName = hostName;

    }

    // For prod Redirection URL
    if (domainName === AppConstants.domain.prod_ui_url || domainName === AppConstants.domain.rts_qa_ui_url || domainName === AppConstants.domain.capx_qa_ui_url) {

      domainName = domainName + '/';

    }
    domainName = encodeURIComponent(domainName);

    console.log(`getOpenIdToken called from auth.service and the domain name is ${domainName}`);

    const stateKey = this.commonFunctionsHelper.generateUUID();

    const nonceKey = this.commonFunctionsHelper.generateUUID();

    const oAuthUrl = environment.oAuthUrl;

    const oAuthClientId = environment.oAuthClientId;

    this.codeVerifier = UtilityService.generateCodeVerifier();

    console.log('code verifier', this.codeVerifier);

    const codeChallenge = await UtilityService.generateCodeChallenge(this.codeVerifier);

    localStorage.setItem('codeVerifier', this.codeVerifier);

    localStorage.setItem('nonce_key', nonceKey);
    /*const oAuthUrlWithQueryString = oAuthUrl +
      '?client_id=' + oAuthClientId +
      '&redirect_uri=' + domainName +
      '&response_type=id_token token' +
      '&state=' + stateKey +
      '&nonce=' + nonceKey +
      '&scope=openid profile NewsConnectMFA' +
      '&pfidpadapterid=CompMFAAdapter';*/
      console.log('oAuthRedirctUrl::',environment.oAuthRedirctUrl);
    const oAuthUrlWithQueryString = oAuthUrl +
      '?client_id=' + oAuthClientId +
      '&redirect_uri=' + environment.oAuthRedirctUrl +
      '&response_type=code' +
      '&scope=openid' +
      '&code_challenge=' + codeChallenge +
      '&code_challenge_method=S256';
      //'&nonce=qwrqewrq';

    console.log('getOpenIdToken', oAuthUrlWithQueryString);
    this.tokenReDirect(oAuthUrlWithQueryString);

  }

  public tokenReDirect(url) {

    window.location.replace(url);

  }

  /**
   * Add the jwttoken to S3 link
   *
   */
  public addJWTTokenToLink(html: string, tagName: string) {

    try {

      // Create a new DOMParser
      const parser = new DOMParser();

      // Parse the HTML string into a document
      const doc = parser.parseFromString(html, 'text/html');

      // Get all nodes of the specified tag name
      const nodes = Array.from(doc.querySelectorAll(tagName));

      nodes.forEach((imgEle: HTMLImageElement) => {

        console.log(`Before updating Domain name ${imgEle.src}`);

        // imgEle.src = this.formS3Link(imgEle.src); //add jwttoken
        // images are in different S3 bucket. So even redirect api url is not helpful to get the images which were uploaded from old env.
        // changes have to be done in backend to make this work
        imgEle.src = Common.formS3Link(this.urlRedirectService.getRedirectAPIURL(imgEle.src));
        console.log(`After updating Domain name and adding new token ${imgEle.src}`);

      });

      const serializer = new XMLSerializer();

      return serializer.serializeToString(doc);

    } catch (error) {

      console.error('Error in addJWTTokenToLink event : ', error);

      return html;

    }

  }

  /**
   * Removes the jwttoken from S3 link
   *
   */
  public removeJWTTokenFromLink(html: string, tagName: string) {

    try {

      // Create a new DOMParser
      const parser = new DOMParser();

      // Parse the HTML string into a document
      const doc = parser.parseFromString(html, 'text/html');

      // Get all nodes of the specified tag name
      const nodes = Array.from(doc.querySelectorAll(tagName));

      nodes.forEach((imgEle: HTMLImageElement) => {

        imgEle.src = imgEle.src.split('?ncxjwttoken')[0]; // remove jwttoken

      });

      const serializer = new XMLSerializer();

      return serializer.serializeToString(doc);

    } catch (error) {

      console.error('Error in removeJWTTokenFromLink event : ', error);

      return html;

    }

  }

  public logoutUser() {

    this.commonFunctionsHelper.clearLocalStorageForAuth();
    this.logoutReDirect();

  }

  public logoutReDirect() {

    const oAuthLogoutUrl = environment.oAuthLogoutUrl;

    window.location.href = oAuthLogoutUrl;

  }

}