<div #guestBookingTable>
   <!-- Related Content Height - {{ relatedContentHeight }} -- Table Height - {{ tableHeight }} --- Related Content Top -
   {{ relatedContentTop }} --- Height to be removed - {{ heightToBeRemoved }} -->

   <!-- <div
      style="background-color: green"
      [style.height]="tableHeight + 'px'"></div> -->

   <nz-table
      nzBordered
      class="related-content-table"
      [ngClass]="{ 'no-content-table': relatedContentData.length == 0 }"
      [nzData]="relatedContentData"
      [nzFrontPagination]="false"
      [nzTotal]="tableParams.total"
      [nzPageIndex]="tableParams.page"
      [nzPageSize]="tableParams.limit"
      nzShowSizeChanger
      [nzLoading]="!isLoaded"
      [nzLoadingDelay]="750"
      (nzPageIndexChange)="onChangePage($event)"
      (nzPageSizeChange)="onChangePageSize($event)"
      [nzScroll]="{ y: tableHeight + 'px' }"
      nzShowQuickJumper>
      <thead #tableHeader>
         <tr>
            <th
               [nzWidth]="preferenceColumns['DATE'].width + 'px'"
               [nzLeft]="false"
               [rowspan]="preferenceColumns['DATE'].rowSpan"
               [nzSortFn]="true"
               [nzSortOrder]="dateSortOrder"
               (nzSortOrderChange)="guestBookingsDateSortEvent($event)">
               Date
            </th>

            <th
               [nzWidth]="preferenceColumns['TIME'].width + 'px'"
               [rowspan]="preferenceColumns['TIME'].rowSpan">
               Time
            </th>

            <th
               [nzWidth]="preferenceColumns['APP'].width + 'px'"
               [rowspan]="preferenceColumns['APP'].rowSpan"
               [nzFilters]="appFilters"
               [nzFilterFn]="true"
               (nzFilterChange)="appFilterEvent($event)"
               nzCustomFilter>
               App
               <nz-filter-trigger
                  [(nzVisible)]="appFiltersVisible"
                  (nzVisibleChange)="visibleChangeEventInAppFilters($event)"
                  [nzDropdownMenu]="appTemplate"
                  [nzActive]="true">
                  <span
                     nz-icon
                     nzType="filter"
                     nzTheme="fill"
                     [ngClass]="resetAppFiltersButton ? 'blueIcon' : 'grayIcon'"></span>
               </nz-filter-trigger>
            </th>

            <th
               [nzWidth]="preferenceColumns['GUEST'].width + 'px'"
               nzCustomFilter>
               Guest
               <nz-filter-trigger
                  [(nzVisible)]="guestSearchVisible"
                  [nzActive]="guestSearchText.length > 0"
                  [nzDropdownMenu]="guestTemplate"
                  (nzVisibleChange)="guestVisibleChange(guestSearchVisible)">
                  <span
                     nz-icon
                     nzType="search"></span>
               </nz-filter-trigger>
            </th>

            <th
               [nzWidth]="preferenceColumns['SHOW'].width + 'px'"
               nzCustomFilter>
               Show
               <nz-filter-trigger
                  [(nzVisible)]="showSearchVisible"
                  [nzActive]="showSearchText.length > 0"
                  [nzDropdownMenu]="showTemplate"
                  (nzVisibleChange)="showVisibleChange(showSearchVisible)">
                  <span
                     nz-icon
                     nzType="search"></span>
               </nz-filter-trigger>
            </th>

            <th
               [nzWidth]="preferenceColumns['SEGMENT'].width + 'px'"
               nzCustomFilter>
               Segment
               <nz-filter-trigger
                  [(nzVisible)]="segmentSearchVisible"
                  [nzActive]="segmentSearchText.length > 0"
                  [nzDropdownMenu]="segmentTemplate"
                  (nzVisibleChange)="segmentVisibleChange(segmentSearchVisible)">
                  <span
                     nz-icon
                     nzType="search"></span>
               </nz-filter-trigger>
            </th>
         </tr>
      </thead>
      <tbody>
         <ng-container *ngFor="let data of relatedContentData; let i = index">
            <tr>
               <td [nzLeft]="false">
                  {{ data.zOnAirDateTime | date: 'MM/dd/yyyy' }}
               </td>

               <td>
                  <!-- <span
                     class="text-selected"
                     *ngIf="data.zrouterlink == null">
                     {{ data.zOnAirDateTime | date: 'hh:mm a' }}
                  </span> -->

                  <a
                     (click)="openApp(data.zrouterlink, 'guest-tracker')"
                     [href]="data.zrouterlink">
                     {{ data.zOnAirDateTime | date: 'hh:mm a' }}
                  </a>
               </td>

               <td>
                  <div class="appearances">
                     <div>
                        <span
                           class="reload"
                           *ngIf="data.recurrent == true"
                           nz-icon
                           nzType="reload"
                           nzTheme="outline"></span>
                     </div>
                     <div>
                        <span
                           *ngIf="data.cancelled == true"
                           class="circle"
                           nz-icon
                           nzType="close-circle"
                           nzTheme="fill"></span>
                     </div>
                  </div>
               </td>

               <td [nzEllipsis]="true">
                  <span
                     nz-popover
                     [nzPopoverTitle]=""
                     [nzPopoverContent]="data.guestFirstName + ' ' + data.guestMiddleName + ' ' + data.guestLastName">
                     {{ data.guestFirstName }} {{ data.guestMiddleName }} {{ data.guestLastName }}
                  </span>
               </td>

               <td [nzEllipsis]="true">
                  <span
                     nz-popover
                     [nzPopoverTitle]=""
                     [nzPopoverContent]="data.showName">
                     {{ data.showName }}
                  </span>
               </td>

               <td [nzEllipsis]="true">
                  <span
                     nz-popover
                     [nzPopoverTitle]=""
                     [nzPopoverContent]="data.topic">
                     {{ data.topic }}
                  </span>
               </td>
            </tr>
         </ng-container>
      </tbody>
   </nz-table>
</div>

<!-- GUEST COLUMN SEARCH -->
<nz-dropdown-menu
   #guestTemplate
   Menu="nzDropdownMenu">
   <div class="search-filter">
      <div class="search-box">
         <input
            type="text"
            nz-input
            placeholder="Search Guest(s)"
            (keyup.enter)="searchGuest()"
            [(ngModel)]="guestSearchText" />
         <div class="flex-buttons">
            <button
               nz-button
               nzSize="small"
               nzType="primary"
               (click)="searchGuest()"
               class="search-button"
               [ngClass]="guestSearchText != '' ? 'search-reset-button-on-input' : ''">
               Search
            </button>
            <button
               nz-button
               nzSize="small"
               [ngClass]="guestSearchText != '' ? 'search-reset-button-on-input' : ''"
               (click)="resetGuestSearch()">
               Reset
            </button>
         </div>
      </div>
   </div>
</nz-dropdown-menu>

<nz-dropdown-menu
   #showTemplate
   Menu="nzDropdownMenu">
   <div class="search-filter">
      <div class="search-box">
         <input
            type="text"
            nz-input
            placeholder="Search Show(s)"
            (keyup.enter)="searchShow()"
            [(ngModel)]="showSearchText" />
         <div class="flex-buttons">
            <button
               nz-button
               nzSize="small"
               nzType="primary"
               (click)="searchShow()"
               class="search-button"
               [ngClass]="showSearchText != '' ? 'search-reset-button-on-input' : ''">
               Search
            </button>
            <button
               nz-button
               nzSize="small"
               [ngClass]="showSearchText != '' ? 'search-reset-button-on-input' : ''"
               (click)="resetShowSearch()">
               Reset
            </button>
         </div>
      </div>
   </div>
</nz-dropdown-menu>

<nz-dropdown-menu
   #segmentTemplate
   Menu="nzDropdownMenu">
   <div class="search-filter">
      <div class="search-box">
         <input
            type="text"
            nz-input
            placeholder="Search Segment(s)"
            (keyup.enter)="searchSegment()"
            [(ngModel)]="segmentSearchText" />
         <div class="flex-buttons">
            <button
               nz-button
               nzSize="small"
               nzType="primary"
               (click)="segmentSearchText != priorSegmentSearchText ? searchSegment() : ''"
               class="search-button"
               [ngClass]="segmentSearchText != '' ? 'search-reset-button-on-input' : ''">
               Search
            </button>
            <button
               nz-button
               nzSize="small"
               (click)="resetSegmentSearch()"
               [ngClass]="segmentSearchText != '' ? 'search-reset-button-on-input' : ''">
               Reset
            </button>
         </div>
      </div>
   </div>
</nz-dropdown-menu>

<!-- REQUEST ID TEMPLATES -->
<nz-dropdown-menu
   #appTemplate
   Menu="nzDropdownMenu">
   <div class="ant-table-filter-dropdown">
      <nz-tree
         class="app-filters"
         [nzData]="appFilters"
         nzNoAnimation
         nzCheckable
         nzMultiple
         [nzShowExpand]="false"
         [nzCheckedKeys]="selectedAppFilters"
         (nzCheckBoxChange)="appFilterChange($event)"
         (nzClick)="appFilterClick($event)"></nz-tree>

      <div class="ant-table-filter-dropdown-btns">
         <button
            nz-button=""
            *ngIf="!resetAppFiltersButton"
            class="ant-btn ant-btn-link ant-btn-sm"
            tabindex="-1"
            disabled="true"
            (click)="appFilterResetEvent()">
            <span class="ng-star-inserted">Reset</span>
         </button>

         <button
            nz-button=""
            *ngIf="resetAppFiltersButton"
            class="ant-btn ant-btn-link ant-btn-sm"
            tabindex="-1"
            disabled="false"
            (click)="appFilterResetEvent()">
            <span class="ng-star-inserted">Reset</span>
         </button>

         <button
            nz-button=""
            class="ant-btn ant-btn-primary ant-btn-sm"
            (click)="appFilterOkEvent()">
            <span class="ng-star-inserted">OK</span>
         </button>
      </div>
   </div>
</nz-dropdown-menu>
