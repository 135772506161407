import { AfterViewChecked, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { environment } from '@environments/environment';
import { BreakpointService } from '@services/breakpoint.service';
import { CommonService } from '@services/common-service';
import { GoogleAnalyticsEventService } from '@services/google-analytics-events.service.service';
import { NavigationService } from '@services/navigation-service';
import { ToastService } from '@services/toastService/toastMessage.service';
import { NzFormatEmitEvent } from 'ng-zorro-antd/tree';
import { debounceTime, Subject, takeUntil } from 'rxjs';
import { SubSink } from 'subsink';
import { manipulateGTData } from '../related-content';
import { GUEST_BOOKING_API_FILTERS, GUEST_BOOKING_APP_FILTERS, GUEST_BOOKING_COLUMNS, GUEST_BOOKING_DEFAULT_TABLE_PARAMS, GUEST_BOOKING_METRICS, TableColumns } from '../related-content-utils';

type NBCApp = 'guest-tracker' | 'ncx' | 'news-search' | 'producer-dashboard';
@Component({
  selector: 'guest-booking-related-content',
  templateUrl: './guest-booking-related-content.component.html',
  styleUrls: ['../story-related-content.component.scss', './guest-booking-related-content.component.scss'],
})
export class GuestBookingRelatedContentComponent implements OnInit, OnDestroy, OnChanges, AfterViewChecked {

  // Story information
  @Input() storyId: number = 0;

  @Input() relatedContentHeight: number = 0;

  @Input() relatedContentTop: number = 0;

  @Output() totalRecords: EventEmitter<number> = new EventEmitter<number>();

  isLoaded: boolean = true;

  relatedContentData: any = [];

  headerHeight: number = 0;

  tableHeight: number = 0;

  heightToBeRemoved: number = 0;

  preferenceColumns: { [key: string]: TableColumns } = {};

  columns: any;

  private subs = new SubSink();

  dateSortOrder: string = null;
  apiQueryFilters: any;
  appFilters: any;
  tableParams: any;

  resetAppFiltersButton: boolean = false;
  appFiltersVisible: boolean = false;
  selectedAppFilters: any = [];

  segmentSearchVisible: boolean = false;
  segmentSearchText: string = '';
  priorSegmentSearchText: string = '';

  allCount: number = 200;
  bookingsCount: number = 50;
  callOutCount: number = 50;
  appearancesCount: number = 100;

  metrics = [
    { text: GUEST_BOOKING_METRICS.ALL, isSelected: false, count: 0 },
    { text: GUEST_BOOKING_METRICS.BOOKINGS, isSelected: false, count: 0 },
    { text: GUEST_BOOKING_METRICS.APPEARANCES, isSelected: false, count: 0 }];


  guestSearchVisible: boolean = false;
  guestSearchText: string = '';
  priorGuestSearchText: string = '';

  showSearchVisible: boolean = false;
  showSearchText: string = '';
  priorShowSearchText: string = '';

  appFilterText: string = '';
  priorAppFilterText: string = '';

  resizeWindow: Subject<string> = new Subject();

  private destroy$ = new Subject();


  constructor(
    private breakpointService: BreakpointService,
    private cService: CommonService,
    private toastService: ToastService,
    private gaService: GoogleAnalyticsEventService,
    private navigationService: NavigationService
  ) { }

  ngOnInit() {

    this.resizeWindow.pipe(debounceTime(100), takeUntil(this.destroy$)).subscribe(() => {

      this.windowResize();
    });

    this.InitPropsAndLoadColumns();

    if (this.storyId)
      this.loadRelatedContent(true);

  }

  InitPropsAndLoadColumns() {
    this.appFilters = JSON.parse(JSON.stringify(GUEST_BOOKING_APP_FILTERS));

    this.apiQueryFilters = JSON.parse(JSON.stringify(GUEST_BOOKING_API_FILTERS));

    this.tableParams = JSON.parse(JSON.stringify(GUEST_BOOKING_DEFAULT_TABLE_PARAMS));

    this.loadColumns();
  }

  ngOnChanges(changes: SimpleChanges) {

    if (changes.storyId && changes.storyId.currentValue) {

      // Fix- NRT-15228 - Filter reset
      this.InitPropsAndLoadColumns();

      this.resetFilters();

      this.loadRelatedContent(true);

    }

  }

  ngAfterViewChecked() {

    this.windowResizeEvent();

  }


  /**
   * Resize event to calculate the height of the inner body to enable scroll
   **/
  public windowResizeEvent() {

    this.heightToBeRemoved = (16 * 3) + (24 * 2) + (40 * 2);

    this.tableHeight = this.relatedContentHeight - this.heightToBeRemoved;

  }

  ngOnDestroy() {

    this.subs.unsubscribe();

    this.destroy$.complete();

  }

  /**
   * Make API call to get the related content for the story
   *
   */
  loadRelatedContent(emitTotalRecords: boolean = false) {

    this.isLoaded = false;

    this.apiQueryFilters.filter.storyID = this.storyId;// 1733260492448;//1732085587829;

    let apiResponse = this.cService.serviceRequestCommon('post', environment.gtRequestAPI, '', this.apiQueryFilters);

    apiResponse.subscribe((response: any) => {

      let results = response?.results || [];

      if (Array.isArray(results)) {

        manipulateGTData(response.gtBookingEnvUrl, results);

        this.relatedContentData = [...results];

        this.tableParams.total = response.total || 0;

        if (emitTotalRecords) {
          this.totalRecords.emit(this.tableParams.total);
        }

      }

    }, (error: any) => {

      console.error('Load Related Content', error);

      this.toastService.createMessage('error', 'Error while loading related content. Please try again.');

    }).add(() => {

      this.isLoaded = true;

    });

  }

  get isMobile(): boolean {

    return this.breakpointService.isMobile.value;

  }


  /**
    * Changing pages
    * @param page {number}
    *
    */
  onChangePage(page: number) {

    this.tableParams.page = page;
    this.apiQueryFilters.page.startIndex = ((page - 1) * this.apiQueryFilters.page.size) + 1;

    this.loadRelatedContent();

  }

  /**
   * Changing number of items to display per page
   * @param limit {number}
   *
   */
  onChangePageSize(limit: number) {

    this.tableParams.limit = limit;
    this.apiQueryFilters.page.size = limit;

    // If Page size changed then we need to reset page index as 1 as well start also
    const page = 1;
    this.tableParams.page = page;
    this.apiQueryFilters.page.startIndex = ((page - 1) * this.apiQueryFilters.page.size) + 1;

    this.loadRelatedContent();
  }

  navigationTo(id: string) {

  }



  loadColumns() {

    this.preferenceColumns = {};

    this.columns = JSON.parse(JSON.stringify(GUEST_BOOKING_COLUMNS));;

    this.columns.forEach((column: TableColumns) => {
      this.preferenceColumns[column.value] = column;
    });

  }

  appFilterResetEvent() {
    this.resetAppFiltersButton = false;

    this.appFiltersVisible = false;

    this.selectedAppFilters = [];

    this.appFilterCustomEvent();

  }

  appFilterOkEvent() {
    this.resetAppFiltersButton = true;

    this.appFiltersVisible = false;

    this.appFilterCustomEvent();

  }

  appFilterCustomEvent() {
    this.resetTableParams();

    if (this.selectedAppFilters?.length == 0) {
      this.apiQueryFilters.filter.app = "excludeCancelIfNotRecurring";
    }
    else
      if (this.selectedAppFilters.includes('all')) {
        this.apiQueryFilters.filter.app = "excludeCancelIfNotRecurring";
      }
      else {
        this.apiQueryFilters.filter.app = 'recurring';
      }

    this.appFilterText = this.apiQueryFilters.filter.app[0];

    if (this.appFilterText != this.priorAppFilterText) {
      this.priorAppFilterText = this.apiQueryFilters.filter.app[0];

      this.loadRelatedContent();
    }
  }

  appFilterClick(event: NzFormatEmitEvent): void {

    this.selectedAppFilters = [];

    if (event.node?.title == 'Recurring') {
      event.node?.setChecked(!event.node?.isChecked);
    }

    if (event.node?.title == 'All') {
      event.node?.setChecked(!event.node?.isChecked);
    }

    if (event.node?.isChecked && event.node?.title == 'Recurring') {
      this.selectedAppFilters = ['recurring'];
    }

    if (event.node?.isChecked && event.node?.title == 'All') {
      this.selectedAppFilters = ['all'];
    }

    if (this.selectedAppFilters.length > 0) { this.resetAppFiltersButton = true; } else { this.resetAppFiltersButton = false; }

    // console.log(this.selectedAppFilters);
  }

  appFilterChange(event: NzFormatEmitEvent): void {

    this.selectedAppFilters = [];

    if (event.node?.isChecked && event.node?.title == 'Recurring') {
      this.selectedAppFilters = ['recurring'];
    }

    if (event.node?.isChecked && event.node?.title == 'All') {
      this.selectedAppFilters = ['all'];
    }

    if (this.selectedAppFilters.length > 0) { this.resetAppFiltersButton = true; } else { this.resetAppFiltersButton = false; }

    // console.log(this.selectedAppFilters);

  }

  visibleChangeEventInAppFilters(event: any) {
    if (event) {
    } else {
      this.appFiltersVisible = false;
    }
  }

  appFilterEvent(event: any) {

    this.resetTableParams();

    console.log(this.appFilters);

    if (event?.length == 0) {
      this.apiQueryFilters.filter.app = "excludeCancelIfNotRecurring";
    }
    else
      if (event.includes('excludeCancelIfNotRecurring') || event.length > 1) {
        this.apiQueryFilters.filter.app = "excludeCancelIfNotRecurring";
      }
      else {
        this.apiQueryFilters.filter.app = 'recurring';
      }

    this.loadRelatedContent();

  }

  guestBookingsDateSortEvent(event: any) {

    if (event === null && this.apiQueryFilters.sort.field !== 'onAirDate') return;
    this.resetTableParams();

    if (event == null) {
      this.apiQueryFilters.sort.field = 'onAirDate';
      this.apiQueryFilters.sort.order = 'descending';
    } else {
      this.apiQueryFilters.sort.field = 'onAirDate';
      this.apiQueryFilters.sort.order = (event == 'ascend') ? 'ascending' : 'descending';
    }

    this.dateSortOrder = event;

    this.loadRelatedContent();
  }

  resetTableParams() {

    const currentTableParams = JSON.parse(JSON.stringify(this.tableParams));

    let defaultTableParams = JSON.parse(JSON.stringify(GUEST_BOOKING_DEFAULT_TABLE_PARAMS));

    defaultTableParams.limit = currentTableParams.limit;

    this.tableParams = JSON.parse(JSON.stringify(defaultTableParams));

    this.apiQueryFilters.page.startIndex = 1;

  }

  onMetricClick(metric) {

    this.metrics.forEach(element => {
      element.isSelected = false;
    });

    metric.isSelected = !metric.isSelected;

  }


  /** 
   * Search for a guest
  */
  searchGuest(): void {
    this.resetTableParams();
    this.guestSearchVisible = false;
    if (this.guestSearchText.length === 0) {
      delete this.apiQueryFilters.filter.guestName;
    } else {
      this.apiQueryFilters.filter.guestName = this.guestSearchText + '*';
    }
    this.priorGuestSearchText = this.guestSearchText;
    this.loadRelatedContent();
  }

  resetGuestSearch(): void {
    this.guestSearchVisible = false;
    this.guestSearchText = '';
    delete this.apiQueryFilters.filter.guestName;
    this.priorGuestSearchText = '';
    this.loadRelatedContent();
  }

  guestVisibleChange(event: boolean): void {
    if (!event && this.guestSearchText !== this.priorGuestSearchText) {
      this.searchGuest();
    }
  }

  /** 
  * Search for a show
  */
  searchShow(): void {
    this.resetTableParams();
    this.showSearchVisible = false;
    if (this.showSearchText.length === 0) {
      delete this.apiQueryFilters.filter.showName;
    } else {
      this.apiQueryFilters.filter.showName = this.showSearchText + '*';
    }
    this.priorShowSearchText = this.showSearchText;
    this.loadRelatedContent();
  }

  resetShowSearch(): void {
    this.showSearchVisible = false;
    this.showSearchText = '';
    delete this.apiQueryFilters.filter.showName;
    this.priorShowSearchText = '';
    this.loadRelatedContent();
  }

  showVisibleChange(event: boolean): void {
    if (!event && this.showSearchText !== this.priorShowSearchText) {
      this.searchShow();
    }
  }


  /**
   * Search for a segment
   */
  searchSegment(): void {
    this.resetTableParams();
    this.segmentSearchVisible = false;
    if (this.segmentSearchText.length === 0) {
      delete this.apiQueryFilters.filter.topic;
    } else {
      this.apiQueryFilters.filter.topic = this.segmentSearchText + '*';
    }
    this.priorSegmentSearchText = this.segmentSearchText;
    this.loadRelatedContent();
  }

  resetSegmentSearch(): void {
    this.segmentSearchVisible = false;
    this.segmentSearchText = '';
    delete this.apiQueryFilters.filter.topic;
    this.priorSegmentSearchText = '';
    this.loadRelatedContent();
  }

  segmentVisibleChange(event: boolean): void {
    if (!event && this.segmentSearchText !== this.priorSegmentSearchText) {
      this.searchSegment();
    }
  }

  /**
  * Open selected ProdReq app for the environment
  *
  */
  openApp(url: string, key: NBCApp): boolean {

    if (this.navigationService.isIOSMobileApp.value) {
      return true;
    } else {
      const win = window.open();
      if (win) {
        win.opener = null;
        win.location.href = url;
        this.gaService.sendEvent('Story Landing - Related Content', `Open ${key}`, url, 1);
        return false;
      }
    }
  }

  windowResize() {
    this.heightToBeRemoved = (16 * 3) + (24 * 2) + (40 * 2);

    this.tableHeight = this.relatedContentHeight - this.heightToBeRemoved;

    // this.resizeColumnWidths();
  }


  isEllipsisApplied(element: HTMLElement): boolean {
    return element.classList.contains('ant-table-cell-ellipsis');
  }

  resetFilters() {
    this.dateSortOrder = null;
    this.resetAppFiltersButton = false;
    this.appFiltersVisible = false;
    this.selectedAppFilters = [];

    this.segmentSearchVisible = false;
    this.segmentSearchText = '';
    this.priorSegmentSearchText = '';

    this.guestSearchVisible = false;
    this.guestSearchText = '';
    this.priorGuestSearchText = '';

    this.showSearchVisible = false;
    this.showSearchText = '';
    this.priorShowSearchText = '';

    this.appFilterText = '';
    this.priorAppFilterText = '';
  }

}


