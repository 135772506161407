<!-- <div
   #tableHeaderSearch
   class="disabled">
   <div class="table-header-search">
      <nz-input-group
         nzSearch
         [nzAddOnAfter]="suffixIconButton">
         <input
            type="text"
            nz-input
            (ngModelChange)="search()"
            [(ngModel)]="idSearchText"
            placeholder="Search IDs here..." />
      </nz-input-group>
      <ng-template #suffixIconButton>
         <button
            (click)="searchId()"
            nz-button
            nzSearch>
            <span
               nz-icon
               nzType="search"></span>
         </button>
      </ng-template>
   </div>
</div> -->

<div #crewTable>
   <nz-table
      nzBordered
      class="related-content-table"
      [ngClass]="{ 'no-content-table': relatedContentData.length == 0 }"
      [nzData]="relatedContentData"
      [nzFrontPagination]="false"
      [nzTotal]="tableParams.total"
      [nzPageIndex]="tableParams.page"
      [nzPageSize]="tableParams.limit"
      nzShowSizeChanger
      [nzLoading]="!isLoaded"
      [nzLoadingDelay]="750"
      (nzPageIndexChange)="onChangePage($event)"
      (nzPageSizeChange)="onChangePageSize($event)"
      [nzScroll]="{ y: tableHeight + 'px' }"
      nzShowQuickJumper>
      <thead #tableHeader>
         <tr class="disabled">
            <th
               [nzWidth]="preferenceColumns['TYPE'].width + 'px'"
               [rowspan]="preferenceColumns['TYPE'].rowSpan"
               [nzFilters]="typeFilters"
               [nzFilterFn]="true"
               (nzFilterChange)="typeFilterEvent($event)">
               {{ preferenceColumns['TYPE'].text }}
            </th>

            <th
               [nzWidth]="preferenceColumns['IDSTATUS'].width + 'px'"
               nzCustomFilter>
               {{ preferenceColumns['IDSTATUS'].text }}
               <nz-filter-trigger
                  [nzDropdownMenu]="statusFilterTemplate"
                  (nzVisibleChange)="visibleChangeEventRequestStatus($event)"
                  [(nzVisible)]="requestStatusVisible"
                  [nzActive]="resetStatusButton">
                  <span
                     nz-icon
                     nzType="filter"
                     nzTheme="fill"></span>
               </nz-filter-trigger>
               <nz-filter-trigger
                  [(nzVisible)]="idSearchVisible"
                  [nzActive]="idSearchText.length > 0"
                  [nzDropdownMenu]="idTemplate">
                  <span
                     nz-icon
                     nzType="search"></span>
               </nz-filter-trigger>
            </th>

            <th
               [nzWidth]="preferenceColumns['STARTDATE'].width + 'px'"
               [nzLeft]="false"
               [rowspan]="preferenceColumns['STARTDATE'].rowSpan"
               [nzSortFn]="true"
               [nzSortOrder]="dateSortOrder"
               (nzSortOrderChange)="startDateSortEvent($event)">
               {{ preferenceColumns['STARTDATE'].text }}
            </th>

            <th
               [nzWidth]="preferenceColumns['LOCATION'].width + 'px'"
               nzCustomFilter>
               {{ preferenceColumns['LOCATION'].text }}
               <nz-filter-trigger
                  [(nzVisible)]="locationSearchVisible"
                  [nzActive]="locationSearchText.length > 0"
                  [nzDropdownMenu]="locationTemplate"
                  (nzVisibleChange)="locationVisibleChange(locationSearchVisible)">
                  <span
                     nz-icon
                     nzType="search"></span>
               </nz-filter-trigger>
            </th>

            <th
               [nzWidth]="preferenceColumns['CREWCOUNT'].width + 'px'"
               [rowspan]="preferenceColumns['CREWCOUNT'].rowSpan">
               {{ preferenceColumns['CREWCOUNT'].text }}
            </th>

            <th
               [nzWidth]="preferenceColumns['REQUESTER'].width + 'px'"
               nzCustomFilter>
               {{ preferenceColumns['REQUESTER'].text }}
               <nz-filter-trigger
                  [(nzVisible)]="requesterSearchVisible"
                  [nzActive]="requesterSearchText.length > 0"
                  [nzDropdownMenu]="requesterTemplate"
                  (nzVisibleChange)="requesterVisibleChange(requesterSearchVisible)">
                  <span
                     nz-icon
                     nzType="search"></span>
               </nz-filter-trigger>
            </th>

            <th
               [nzWidth]="preferenceColumns['SHOWPROJECT'].width + 'px'"
               [rowspan]="preferenceColumns['SHOWPROJECT'].rowSpan"
               nzCustomFilter>
               {{ preferenceColumns['SHOWPROJECT'].text }}
               <nz-filter-trigger
                  [(nzVisible)]="showOrProjectSearchVisible"
                  [nzActive]="showOrProjectSearchText.length > 0"
                  [nzDropdownMenu]="showOrProjectTemplate"
                  (nzVisibleChange)="ShowOrProjectVisibleChange(showOrProjectSearchVisible)">
                  <span
                     nz-icon
                     nzType="search"></span>
               </nz-filter-trigger>
            </th>

            <th [nzWidth]="preferenceColumns['FIELDCONTACT'].width + 'px'">
               {{ preferenceColumns['FIELDCONTACT'].text }}
               <!-- <nz-filter-trigger
                  [(nzVisible)]="fieldContactSearchVisible"
                  [nzActive]="fieldContactSearchText.length > 0"
                  [nzDropdownMenu]="fieldContactTemplate"
                  (nzVisibleChange)="fieldContactVisibleChange(fieldContactSearchVisible)">
                  <span
                     nz-icon
                     nzType="search"></span>
               </nz-filter-trigger> -->
            </th>

            <th [nzWidth]="preferenceColumns['PHONENUMBER'].width + 'px'">
               {{ preferenceColumns['PHONENUMBER'].text }}
               <!-- <nz-filter-trigger
                  [(nzVisible)]="phoneNumberSearchVisible"
                  [nzActive]="phoneNumberSearchText.length > 0"
                  [nzDropdownMenu]="phoneNumberTemplate"
                  (nzVisibleChange)="phoneNumberVisibleChange(phoneNumberSearchVisible)">
                  <span
                     nz-icon
                     nzType="search"></span>
               </nz-filter-trigger> -->
            </th>
         </tr>
      </thead>
      <tbody>
         @for (data of relatedContentData; track data) {
            <tr>
               <td [nzEllipsis]="true">
                  <span
                     nz-popover
                     [nzPopoverTitle]=""
                     [nzPopoverContent]="data.content.title">
                     {{ data.content.title }}
                  </span>
               </td>

               <td>
                  <a
                     class="flex"
                     [ngStyle]="{ color: requestStatusDetails[data.content.statusIndex]?.color }"
                     (click)="openApp(data.content.zrouterlink, 'producer-dashboard')"
                     [href]="data.content.zrouterlink">
                     <span
                        [style.color]="requestStatusDetails[data.content.statusIndex]?.color"
                        [ngClass]="requestStatusDetails[data.content.statusIndex]?.class">
                        <span
                           nz-icon
                           class="status-icon"
                           [nzType]="requestStatusDetails[data.content.statusIndex]?.icon"
                           [nzTheme]="
                              requestStatusDetails[data.content.statusIndex]?.icon === 'sync' ? 'outline' : 'fill'
                           "></span>
                        <span
                           nz-tooltip
                           nzTooltipPlacement="bottomLeft"
                           nzTooltipColor="white">
                           {{ data.content.zid }}
                        </span>
                     </span>
                     <span
                        nz-icon
                        class="id-right-icon"
                        nzType="right"
                        nzTheme="outline"></span>
                  </a>
               </td>

               <!-- <td>{{ data.content.startDate | date: 'MM/dd' }} ({{ data.content.daysShoot }})</td> -->
               <td class="date-right-align">{{ data.content.startDateString }}</td>

               <td [nzEllipsis]="true">
                  <span
                     nz-popover
                     [nzPopoverTitle]=""
                     [nzPopoverContent]="data.content.location">
                     {{ data.content.location }}
                  </span>

                  <!-- <span
                     *ngIf="data.content.zLocationRemaining"
                     nz-popover
                     [nzPopoverTitle]=""
                     [nzPopoverContent]="data.content.zLocationDisplayList">
                     {{ data.content.zLocationDisplayString }}
                  </span>

                  <span *ngIf="!data.content.zLocationRemaining">{{ data.content.zLocationDisplayString }}</span> -->
               </td>

               <!-- <td>
                  <span
                     *ngIf="data.content.resource.length > 0"
                     class="resource-count">
                     {{ data.content.resource.length }}
                     <span
                        nz-icon
                        class="expand-icon"
                        [ngClass]="data.expand ? 'expand-icon-minus' : ''"
                        [nzType]="data.expand ? 'minus' : 'plus'"
                        nzTheme="outline"
                        (click)="data.expand = !data.expand"></span>
                  </span>
               </td> -->

               <td
                  class="crew-count"
                  [nzShowExpand]="data.content.resource.length > 0"
                  [(nzExpand)]="data.expand">
                  {{ data?.content.resource.length > 0 ? data?.content.resource.length : '' }}
               </td>

               <!-- <td>
                  <ng-container *ngIf="data?.content.resource.length > 0; else emptyCell">
                     <span
                        class="crew-count"
                        [(nzExpand)]="data.expand">
                        {{ data.content.resource.length }}
                     </span>
                  </ng-container>
                  <ng-template #emptyCell></ng-template>
               </td> -->

               <td [nzEllipsis]="true">
                  <!-- <span
                     *ngIf="data.content.zRequestersRemaining"
                     nz-popover
                     [nzPopoverTitle]=""
                     [nzPopoverContent]="data.content.zRequestersDisplayList">
                     {{ data.content.zRequestersDisplayString }}
                  </span>

                  <span *ngIf="!data.content.zRequestersRemaining">{{ data.content.zRequestersDisplayString }}</span> -->
                  <span
                     nz-popover
                     [nzPopoverTitle]=""
                     [nzPopoverContent]="data.content.requester">
                     {{ data.content.requester }}
                  </span>
               </td>

               <td [nzEllipsis]="true">
                  <!-- <span
                     *ngIf="data.content.zShowUnitsRemaining"
                     nz-popover
                     [nzPopoverTitle]="showUnitTitleTemplate"
                     [nzPopoverContent]="showUnitDataTemplate">
                     {{ data.content.zShowUnitsDisplayString }}
                  </span>

                  <span *ngIf="!data.content.zShowUnitsRemaining">{{ data.content.zShowUnitsDisplayString }}</span> -->

                  <span
                     nz-popover
                     nzPopoverPlacement="topLeft"
                     [nzPopoverTitle]="showUnitTitleTemplate"
                     [nzPopoverContent]="showUnitDataTemplate">
                     {{ data.content.zShowUnitsDisplayString }}

                     <ng-template #showUnitTitleTemplate>
                        <span class="popover-title">Show / Project</span>
                     </ng-template>

                     <ng-template #showUnitDataTemplate>
                        <div class="popover-content">
                           <ul class="list-items">
                              <ng-container *ngFor="let show of data.content.zShowUnitsDisplayList">
                                 <li class="">{{ show }}</li>
                              </ng-container>
                           </ul>
                        </div>
                     </ng-template>
                  </span>
               </td>

               <td [nzEllipsis]="true">
                  <!-- <span
                     *ngIf="data.content.zFieldContactRemaining"
                     nz-popover
                     [nzPopoverTitle]=""
                     [nzPopoverContent]="data.content.zFieldContactDisplayList">
                     {{ data.content.zFieldContactDisplayString }}
                  </span>

                  <span *ngIf="!data.content.zFieldContactRemaining">
                     {{ data.content.zFieldContactDisplayString }}
                  </span> -->

                  <span
                     nz-popover
                     [nzPopoverTitle]=""
                     [nzPopoverContent]="data.content.fieldContactName">
                     {{ data.content.fieldContactName }}
                  </span>
               </td>

               <td>{{ data.content.producerCellNumber }}</td>
            </tr>
            <tr [nzExpand]="data.expand">
               <nz-table
                  nzBordered
                  #innerTable
                  class="related-content-table inner-related-content-table"
                  [nzData]="data.content.resource"
                  [nzShowPagination]="false"
                  [nzLoading]="!isLoaded"
                  [nzLoadingDelay]="750"
                  [nzScroll]="{ y: 200 + 'px' }">
                  <thead>
                     <tr>
                        <th [nzWidth]="innerTablePreferenceColumns['POSITION'].width + 'px'">
                           {{ innerTablePreferenceColumns['POSITION'].text }}
                        </th>

                        <th [nzWidth]="innerTablePreferenceColumns['NAME'].width + 'px'">
                           {{ innerTablePreferenceColumns['NAME'].text }}
                        </th>

                        <th [nzWidth]="innerTablePreferenceColumns['EMAIL'].width + 'px'">
                           {{ innerTablePreferenceColumns['EMAIL'].text }}
                        </th>

                        <th [nzWidth]="innerTablePreferenceColumns['PHONENUMBER'].width + 'px'">
                           {{ innerTablePreferenceColumns['PHONENUMBER'].text }}
                        </th>
                     </tr>
                  </thead>
                  <tbody>
                     @for (data of data.content.resource; track data) {
                        <tr>
                           <td>{{ data.role }}</td>
                           <td>{{ data.title }}</td>
                           <td class="email">{{ data.email }}</td>
                           <td>{{ data.phone }}</td>
                        </tr>
                     }
                  </tbody>
               </nz-table>
            </tr>
         }
      </tbody>
   </nz-table>
</div>

<!-- GUEST COLUMN SEARCH -->
<nz-dropdown-menu
   #idTemplate
   Menu="nzDropdownMenu">
   <div class="search-filter">
      <div class="search-box">
         <input
            type="text"
            nz-input
            placeholder="Search Id(s)"
            (keyup.enter)="searchId()"
            [(ngModel)]="idSearchText" />
         <button
            nz-button
            nzSize="small"
            nzType="primary"
            (click)="searchId()"
            class="search-button"
            [ngClass]="idSearchText != '' ? 'search-reset-button-on-input' : ''">
            Search
         </button>
         <button
            nz-button
            nzSize="small"
            (click)="resetIdSearch()"
            [ngClass]="idSearchText != '' ? 'search-reset-button-on-input' : ''">
            Reset
         </button>
      </div>
   </div>
</nz-dropdown-menu>

<nz-dropdown-menu
   #locationTemplate
   Menu="nzDropdownMenu">
   <div class="search-filter">
      <div class="search-box">
         <input
            type="text"
            nz-input
            placeholder="Search Location(s)"
            (keyup.enter)="searchLocation()"
            [(ngModel)]="locationSearchText" />
         <button
            nz-button
            nzSize="small"
            nzType="primary"
            (click)="searchLocation()"
            class="search-button"
            [ngClass]="locationSearchText != '' ? 'search-reset-button-on-input' : ''">
            Search
         </button>
         <button
            nz-button
            nzSize="small"
            (click)="resetLocationSearch()"
            [ngClass]="locationSearchText != '' ? 'search-reset-button-on-input' : ''">
            Reset
         </button>
      </div>
   </div>
</nz-dropdown-menu>

<nz-dropdown-menu
   #requesterTemplate
   Menu="nzDropdownMenu">
   <div class="search-filter">
      <div class="search-box">
         <input
            type="text"
            nz-input
            placeholder="Search Requester(s)"
            (keyup.enter)="searchRequester()"
            [(ngModel)]="requesterSearchText" />
         <button
            nz-button
            nzSize="small"
            nzType="primary"
            (click)="searchRequester()"
            class="search-button"
            [ngClass]="requesterSearchText != '' ? 'search-reset-button-on-input' : ''">
            Search
         </button>
         <button
            nz-button
            nzSize="small"
            (click)="resetRequesterSearch()"
            [ngClass]="requesterSearchText != '' ? 'search-reset-button-on-input' : ''">
            Reset
         </button>
      </div>
   </div>
</nz-dropdown-menu>

<nz-dropdown-menu
   #showOrProjectTemplate
   Menu="nzDropdownMenu">
   <div class="search-filter">
      <div class="search-box">
         <input
            type="text"
            nz-input
            placeholder="Search Show / Project"
            (keyup.enter)="searchShowOrProject()"
            [(ngModel)]="showOrProjectSearchText" />
         <button
            nz-button
            nzSize="small"
            nzType="primary"
            (click)="searchShowOrProject()"
            class="search-button"
            [ngClass]="showOrProjectSearchText != '' ? 'search-reset-button-on-input' : ''">
            Search
         </button>
         <button
            nz-button
            nzSize="small"
            (click)="resetShowOrProjectSearch()"
            [ngClass]="showOrProjectSearchText != '' ? 'search-reset-button-on-input' : ''">
            Reset
         </button>
      </div>
   </div>
</nz-dropdown-menu>

<nz-dropdown-menu
   #fieldContactTemplate
   Menu="nzDropdownMenu">
   <div class="search-filter">
      <div class="search-box">
         <input
            type="text"
            nz-input
            placeholder="Search Field Contact(s)"
            (keyup.enter)="searchFieldContact()"
            [(ngModel)]="fieldContactSearchText" />
         <button
            nz-button
            nzSize="small"
            nzType="primary"
            (click)="searchFieldContact()"
            class="search-button"
            [ngClass]="fieldContactSearchText != '' ? 'search-reset-button-on-input' : ''">
            Search
         </button>
         <button
            nz-button
            nzSize="small"
            (click)="resetFieldContactSearch()"
            [ngClass]="fieldContactSearchText != '' ? 'search-reset-button-on-input' : ''">
            Reset
         </button>
      </div>
   </div>
</nz-dropdown-menu>

<nz-dropdown-menu
   #phoneNumberTemplate
   Menu="nzDropdownMenu">
   <div class="search-filter">
      <div class="search-box">
         <input
            type="text"
            nz-input
            placeholder="Search Phone Number(s)"
            (keyup.enter)="searchPhoneNumber()"
            [(ngModel)]="phoneNumberSearchText" />
         <button
            nz-button
            nzSize="small"
            nzType="primary"
            (click)="searchPhoneNumber()"
            class="search-button"
            [ngClass]="phoneNumberSearchText != '' ? 'search-reset-button-on-input' : ''">
            Search
         </button>
         <button
            nz-button
            nzSize="small"
            (click)="resetPhoneNumberSearch()"
            [ngClass]="phoneNumberSearchText != '' ? 'search-reset-button-on-input' : ''">
            Reset
         </button>
      </div>
   </div>
</nz-dropdown-menu>

<!-- STATUS TEMPLATES -->
<nz-dropdown-menu
   #statusFilterTemplate
   Menu="nzDropdownMenu">
   <div class="ant-table-filter-dropdown">
      <div class="table-scroll table-request">
         <div>
            <div class="type-filter request-filter">
               <ng-container *ngFor="let status of statusFilters">
                  <div class="tooltip-text">
                     <p
                        nz-checkbox
                        [nzValue]="status.label"
                        (ngModelChange)="requestStatusTypeSearch($event, status)"
                        [(ngModel)]="status.byDefault">
                        {{ status.text }}
                     </p>
                  </div>
               </ng-container>
            </div>
         </div>
      </div>

      <div class="ant-table-filter-dropdown-btns">
         <button
            nz-button=""
            class="ant-btn ant-btn-link ant-btn-sm"
            tabindex="-1"
            (click)="requestStatusResetEvent()"
            [disabled]="!resetStatusButton">
            <span class="ng-star-inserted">Reset</span>
         </button>

         <button
            nz-button=""
            class="ant-btn ant-btn-link ant-btn-sm"
            (click)="requestStatusOkEvent()">
            <span class="ng-star-inserted">OK</span>
         </button>
      </div>
   </div>
</nz-dropdown-menu>

<nz-modal
   [(nzVisible)]="showTempModal"
   [nzNoAnimation]="true"
   [nzClosable]="false"
   [nzFooter]="null"
   [nzCentered]="true">
   <ng-template #modalTitle></ng-template>
   <div class="modal-content">
      <ng-container *nzModalContent>
         <div class="content">
            <div class="icon-container">
               <span
                  class="icon"
                  nz-icon
                  nzType="notification"
                  nzTheme="outline"></span>
            </div>
            <div>
               <div class="content-header">Coming Soon!</div>
               <div class="content-text">
                  You'll soon be able to view, search and navigate to related Producer Dashboard Crew/Edit/Feed Out/File
                  Ingest requests here.
               </div>
               <div class="close-modal">
                  <button
                     class="modal-close"
                     nz-button
                     nzType="primary"
                     (click)="closeModal()">
                     Ok
                  </button>
               </div>
            </div>
         </div>
      </ng-container>
   </div>
</nz-modal>
